<template>
  <div class="calendarAuth-container">
      <div class="calendarAuth-card" :style="{height:(showPermissionsImage || showPermissionsGIF)?'450px':'250px', border: !showBorder ? 'none' : ''}">
        <div class="error-auth" v-if="showErrorIcon">
            <i  class="ri-error-warning-line"></i>
        </div>
        <div class="calendarAuth-card__title">
            <p class="mb-1" v-if="message">{{message}}</p>
            <p v-else></p>
        </div>
        <div class="calendarAuth-card__titleSmall">
            Looppanel’s bot auto-joins, records and takes AI notes for upcoming calls
        </div>
        <div class="calendarAuth-card__subtitle">
            <p>{{helperText}}</p>
            <div v-if="showPermissionsGIF">
                <img src="https://firebasestorage.googleapis.com/v0/b/poc-project-309214.appspot.com/o/static%2FCalendarPermissions.gif?alt=media&token=9aae19de-bb06-4b4e-94d2-1062655a7db8"/>
                <!-- <video 
                    :autoplay="true"
                    :loop="true"
                >
                    <source src="https://firebasestorage.googleapis.com/v0/b/poc-project-309214.appspot.com/o/static%2FCalendar%20Asset.mov?alt=media&token=737e575a-e236-42b2-b494-cbd9162dee2a"/>
                </video> -->

            </div>
        </div>
        <div class="calendarAuth-card__cta mt-5">
            <button @click="handleAuth" class="pulsating-border">
                <img height="20" width="20" :src="goolgeAuthImage"/>
                <p>Connect Google calendar</p>
            </button>
        </div>
      </div>
  </div>
</template>

<script>
import CalendarAPI from '../../api/calendar/calendar';
import CALENDAR_CONSTANTS from '../../constants/calendar';
import CalendarUtils from '../../utils/calendar';
import { auth } from '@/firebase';

export default {
    props:{
        message:{
            type:String,
            default:'Connect your calendar to auto-record upcoming calls'
        },
        helperText:{
            type:String,
            default:'Please allow these permissions to connect your calendar'
        },
        fetchNewCalendarAuthToken:{
            type:Boolean,
        },
        showErrorIcon:{
            type:Boolean,
            default:false
        },
        calendarAuthToken:{},
        showPermissionsGIF:{
            type:Boolean,
            default:false
        },
        showBorder:{
            type:Boolean,
            default: true
        }
    },
    data(){
        return{
            goolgeAuthImage:'https://firebasestorage.googleapis.com/v0/b/poc-project-309214.appspot.com/o/static%2Fgoogle-icon.png?alt=media&token=b5d3664d-760e-4363-8846-6076c1ae32cb'

        }
    },
    methods: {
        async handleAuth(){
            window.analytics.track("click on google oauth button", {
                    email: auth.currentUser.email,
            });
            
            try{
                let calendarAuthToken;
                if(this.fetchNewCalendarAuthToken){
                    const calendarAuthTokenResponse=await CalendarAPI.getNewCalendarAuthToken(auth.currentUser.uid);
                    console.log({calendarAuthTokenResponse})
                    calendarAuthToken=calendarAuthTokenResponse.data.token;
                }else{
                    calendarAuthToken=this.calendarAuthToken;
                }
                
                const OAuthUrl = CalendarUtils.buildGoogleOAuthUrl({
                    state: {
                        recall_calendar_auth_token: calendarAuthToken,
                        google_oauth_redirect_url: CALENDAR_CONSTANTS.redirect_uri,
                        success_url:`${window.origin}/calendar?status=success`,
                        error_url:`${window.origin}/calendar?status=failure`
                    },
                    redirectUri:CALENDAR_CONSTANTS.redirect_uri,
                    clientId:CALENDAR_CONSTANTS.clientId
                });
                console.log({OAuthUrl})
                window.open(OAuthUrl,"_self");           
                //this.$emit('authenticated')

            }catch(error){
                console.log({error})
            }
            
           
        }
    }
}
</script>

<style scoped>
    .calendarAuth-container{
        display: flex;
        flex: 1;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: auto;
    }

    .calendarAuth-card{
        box-sizing: border-box;

        /* Auto layout */

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 56px 48px;
        width: 635px;
        /* height: 250px; */
        background: #FFFFFF;
        /* Neutrals/200 */
        border: 1px solid #EDEDED;
        border-radius: 16px;
    }

    .calendarAuth-card__title p{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
        /* identical to box height, or 32px */

        text-align: center;

        /* Neutrals/600 */

        color: #4C4C4C;
    }

    .calendarAuth-card__titleSmall{
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: center;
        color:#4c4c4c;
        margin-top: 5px;
        margin-bottom: 0px;
    }
    
    .calendarAuth-card__subtitle p{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        /* identical to box height, or 21px */

        text-align: center;

        /* Neutrals/400 */

        color: #999999;
    }

    .calendarAuth-card__cta button{
        box-sizing: border-box;
        outline:none;
        /* Auto layout */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 16px 12px 12px;
        width: 224px;
        height: 45px;
        /* Neutrals/200 */
        border: 1px solid #5B7EDB;
        border-radius: 44px;
    }

    .calendarAuth-card__cta img{
        height:18px;
        width:18px;
        margin-right: 5px;
    }

    .calendarAuth-card__cta button p{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        margin-bottom: 0px;
        letter-spacing: -0.01em;
        color: #5B7EDB;
    }

    .error-auth i{
        font-size:40px;
        color:#C53030;
    }

</style>