<template>
  <v-dialog 
    v-model="showModal" 
    @click:outside="$emit('toggle-modal')" 
    :width="width"
    :persistent="isPersistent"
    content-class="loop-modal">
          <v-card class="radius-16" style="overflow-y:hidden" :height="height">

            <div v-if="showHighlightedInfo" class="loop-modal-highlightedInfo">
               
              <div class="highlightedInfo-gif__container" :style="{'height':gifContainerHeight}">
                   <slot name="highlightedInfo-gif">

                   </slot>
                 </div>
               <slot name="highlightedInfo-text">

               </slot>
               <slot name="highlightedInfo-img">

               </slot>
            </div>

            <v-card-title class="loop-modal-header mt-0 pt-0 text-h5 d-flex-row" :style="{'padding':'16px 20px !important','border-bottom':showBorder ? '1px solid #EDEDED':'none'}">
              
              <p  :class="isSmallTitle ? 'loop-modal-title-small mb-0' : isLongTitle ? 'loop-modal-title loop-modal-title__long':'loop-modal-title'" :style="{'margin':alignTitle=='center' ? 'auto' : '0'}" >

                <template v-if="hideTitle"></template> 
                <template v-else>{{title}}</template>
                
              </p>

              <span v-if="!hideCloseButton" class="loop-modal-closeBtn" 
                @click="$emit('toggle-modal')" >
                        &#x2715;
              </span>
            </v-card-title>

            <v-card-text class="loop-modal-content flex-col mt-0 mb-0 pb-0 " :style="{padding: defaultPadding? '0px 20px !important':'0' }">
                <slot name="content">

                </slot>

              <div v-if="showCta" class="loop-modal-ctaActionContainer mt-3 mb-3" :style="{'justify-content':smallCTA ? 'center' : 'space-between'}">
                <button @click="$emit('toggle-modal')"  class="cancel-btn" v-if="showCancelButton"
                :style="{
                    'color': cancelBtnColor ? cancelBtnColor : '#4C4C4C',
                    'border':`1px solid ${cancelBtnColor?cancelBtnColor:'#EDEDED'} !important`,
                    'width' : fullWidth ? '48%' : '239px'
                  }
                "
                >
                  <template v-if="cancelButtonText"> 
                    {{ cancelButtonText }}
                  </template>
                  <template v-else>
                    Cancel
                  </template>
                </button>
                <v-btn
                    :class="showCancelButton ? 'mb-0' : 'mb-0' "
                    :color="ctaBtnColor"
                    dark
                    @click="emitCustomCTAEvent"
                    :style="{
                      'font-weight': '600 !important',
                      'border-radius': '20px !important',
                      'height':'40px',
                      'width': fullWidth ? '48%' : (showCancelButton || smallCTA) ? '48%' : '100%',
                      'border':`1px solid ${ctaBtnBorderColor?ctaBtnBorderColor:ctaBtnColor} !important`,
                    }
                    "
                    elevation="0"
                >

                  <slot v-if="showCTAIcon" name="cta_icon">

                  </slot>  
                <!-- //!Remove inline styling   -->
                    <p class="loop-modal-cta mb-0 " :style="{'color':`${ctaBtnTextColor} !important`,}">

                      {{ctaText}}      
                    </p>
                    
                </v-btn>
               
              </div>
              <div class="loop-modal-ctaHelper" v-if="showCtaHelper">
                <slot name="ctaHelper-template">

                </slot>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
</template>


<script>

export default{
    props:{
       gifContainerHeight:{
        default:364
       },
        width:{
          default:480
        },
        height:{
          default:null
        },
        isPersistent:{
          default:false
        },
        showModal:{
            type: Boolean,
            required: true,
        },
        title:{
            type:String,
            // required: true,
        },
        alignTitle:{
          default:'left'
        },
        hideTitle:{
          default: false
        },
        ctaEvent:{
            type: String,
            // required: true,
        },
        ctaBtnColor:{
            type:String,
            // required: true,
        },
        ctaBtnBorderColor:{
          default:null
        },
        ctaBtnTextColor:{
          default:'white'
        },
        smallCTA:{
          default:false
        },
        showCta:{
          default:true
        },
        ctaText:{
            type:String,
            // required: true,
        },
        showCtaHelper:{
          default:false,
          type:Boolean,
        },
        showHighlightedInfo:{
          default:false,
          type:Boolean,
        },
        isLongTitle:{
          default:false,
          type:Boolean,
        },
        isSmallTitle:{
          default:false,
          type:Boolean,
        },
        hideCloseButton:{
          default:false,
          type:Boolean,
        },
        showCancelButton:{
          default:false,
          type:Boolean,
        },
        cancelBtnColor:{},
        showBorder:{
          default: false
        },
        emitToggleEventCustomCTA:{
          default:true
        },
        cancelButtonText:{
          default:null,
        },
        fullWidth:{
          default: false
        },
        defaultPadding:{
          default: true
        },
        projectId: {},
        showCTAIcon:{
          default: false
        }
    
    },
    /** STATE */
    data(){
        return{
           

        }
},

/** FUNCTIONS and HANDLERS */
methods:{

    emitCustomCTAEvent(){
      this.$emit(this.ctaEvent);
      if (this.emitToggleEventCustomCTA){
        this.$emit('toggle-modal')
      }
      
      return;
    },

 },

 /** LIFECYCLE METHODs */
 mounted() {
  this.$emit('modal-mounted');
 }

}

</script>

<style scoped>

    .loop-modal-ctaHelper{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
        height: 15px;
        margin-bottom: 20px;
    }

    .highlightedInfo-gif__container{
      /* height:364px; */
      /* height:270px; */
      /* padding:5px; */
      border-radius: 16px;
    }

    .create-project-modal__container{
      padding: 24px;
    }

    .loop-modal-header,.loop-modal-content{
      padding:0px !important;
    }
    .loop-modal{
        border-radius: 16px !important;
        overflow-y:hidden;
    }

    .radius-16{
        border-radius: 16px !important;
    }
    .loop-modal-title{
        text-align:center; 
        font-weight:600; 
        color:#4C4C4C;
        margin-right: 15px;
        font-family: Inter;
        font-size: 18px;
        line-height: 21.78px;
    }

    .loop-modal-title-small{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      /* identical to box height */


      /* Neutrals/600 */

      color: #4C4C4C;
      margin-right: 10px !important;
    }

    .loop-modal-title__long {
        text-align: left; 
        max-width: 95%;
        word-break:keep-all;
    }

    .loop-modal-closeBtn{
        position: absolute;
        top: 14px;
        right: 24px;
        font-size:16px; 
        /* margin-bottom:15px;  */
        cursor:pointer
    }
    .loop-modal-cta{
        font-weight: 600 !important; 
        font-size:14px;
        text-transform:capitalize;
    }

    .loop-modal-ctaActionContainer{
        display:flex;
        align-items:center;
        justify-content:space-between
    }

    .cancel-btn{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 16px;
        gap: 4px;

        /* width: 239px; */
        height: 40px;

        /* Neutrals/200 */

        /* border: 1px solid #EDEDED; */
        border-radius: 44px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        /* identical to box height, or 21px */

        text-transform: capitalize;

        /* Neutrals/600 */
    }
</style>