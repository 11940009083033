import {
    auth,
    tagsCollection,
    annotationsCollection
  } from "@/firebase";
import CONSTANTS from '../../constants/miscellaneous.js';
import axios from 'axios';
import store from "@/store/index.js";
import {getFunctionUrl} from '@/functions/functionsConfig'
import getWorkspaceIdForProjectQuery from "@/utils/apiUtils.js";

const getAllWorkspaceTags=async(workspaceId)=>{
    const tagsData = await tagsCollection.where("workspace_id","==",workspaceId).get()
    let tags = [];
    for (const tag of await tagsData.docs){
        tags.push({
            ...tag.data(),
            id:tag.id
        })
    }
    return tags;
}

const getAllTags=async(projectId,isCollaborator=false)=>{
    const _workspaceId = getWorkspaceIdForProjectQuery(projectId)
    try {
            // DB_READ
            // Collection: tagsCollection
            // Filters: workspace_id, projectId
            // Type: Multiple fetch

            // DB_READ
            // Collection: tagsCollection
            // Filters: workspace_id, userId, projectId
            // Type: Multiple fetch
            const tagsData= isCollaborator ? await tagsCollection
                                                .where("workspace_id","==",_workspaceId)
                                                .where("projectId","==",projectId)
                                                // .orderBy("createdOn","desc")
                                                .get()
                                        :  await tagsCollection
                                                .where("workspace_id","==",_workspaceId)
                                                .where("userId","==",auth.currentUser.uid)
                                                .where("projectId","==",projectId)
                                                // .orderBy("createdOn","desc")
                                                .get()
                        
        // console.log(tagsData)
        let tags=[];
        for(const tag of await tagsData.docs){
            // console.log(project.id,'-->',project.data().name)
            // console.log(tag.id,tag.data())
            tags.push({
                ...tag.data(),
                id:tag.id,
            })
            
        }                        

        return tags;
    } catch (error) {
        console.error('[DB_READ_ERROR] getAllTags() Error:', error);
    }
}

const getAllProjectAITags=async(projectId,isCollaborator=false)=>{
    const _workspaceId = getWorkspaceIdForProjectQuery(projectId)
    try {
            // DB_READ
            // Collection: tagsCollection
            // Filters: workspace_id, projectId
            // Type: Multiple fetch

            // DB_READ
            // Collection: tagsCollection
            // Filters: workspace_id, userId, projectId
            // Type: Multiple fetch
            const tagsData= isCollaborator ? await tagsCollection
                                                .where("workspace_id","==",_workspaceId)
                                                .where("type","==","ai_tag")
                                                .where("projectId","==",projectId)
                                                // .orderBy("createdOn","desc")
                                                .get()
                                        :  await tagsCollection
                                                .where("workspace_id","==",_workspaceId)
                                                .where("userId","==",auth.currentUser.uid)
                                                .where("projectId","==",projectId)
                                                .where("type","==","ai_tag")
                                                // .orderBy("createdOn","desc")
                                                .get()
                        
        // console.log(tagsData)
        let tags=[];
        for(const tag of await tagsData.docs){
            // console.log(project.id,'-->',project.data().name)
            // console.log(tag.id,tag.data())
            tags.push({
                ...tag.data(),
                id:tag.id,
            })
            
        }                        

        // Sort tags alphabetically by the "name" property
        tags.sort((a, b) => a.name.localeCompare(b.name));

        return tags;
    } catch (error) {
        console.error('[DB_READ_ERROR] getAllProjectAITags() Error:', error);
    }
}

const deleteTagsForProject=async(projectId)=>{
    try{
        // DB_READ
        // Collection: tagsCollection
        // Filters: workspace_id, userId, projectId
        // Type: Multiple fetch
        const tags=await tagsCollection
        .where("workspace_id", "==", store.state.userWorkspace.id)
        .where("userId","==",auth.currentUser.uid)
        .where("projectId","==",projectId)
        // .orderBy("createdOn","desc")
        .get()

        if(tags.docs.length===0){
            // console.log('Empty tagboard');
            return{ status:'success',message:'Tagboard is empty', statusCode:10}
        }
        for(const tag of await tags.docs){
        await tag.ref.delete()        
        } 
        return{ status:'success',message:'Tagboard deleted', statusCode:20}


    }catch(error){
        console.error('[DB_READ_ERROR] deleteTagsForProject() Error:', error);
        return {status:'failure',erorr:error};
    }
    
}

const createNewTag=async(newTag)=>{
    const tagObject={
        ...newTag,
        createdOn: new Date(),
        userId: auth.currentUser.uid,
        updatedOn: new Date(),
        lastUpdatedBy:  auth.currentUser.uid

    }
    try{
        const newTag=await tagsCollection.add(tagObject);
        return {
            status:'success',
            newTag:{
                tagId:newTag.id,
                ...tagObject
            }
        }
    }
    catch(error){
        // console.log(error);
        return{
            status:'error',
            error:error
        }
    }
    
    
}

const createNewTagForProject=async(newTag,projectId,workspaceId)=>{
    const tagObject={
        ...newTag,
        createdOn: new Date(),
        userId: auth.currentUser.uid,
        updatedOn: new Date(),
        projectId: projectId,
        workspace_id:workspaceId,
        taggedNoteCount:0,
        lastUpdatedBy:  auth.currentUser.uid

    }
    try{
        const newTag=await tagsCollection.add(tagObject);
        return {
            status:'success',
            newTag:{
                id:newTag.id,
                ...tagObject
            }
        }
    }
    catch(error){
        // console.log(error);
        return{
            status:'error',
            error:error
        }
    }
    
    
}

const deleteTag=async(tagId)=>{

    try{
        const tagToBeDeleted=await tagsCollection.doc(tagId)
        const deletedTagResponse=await tagToBeDeleted.delete();
        return {
            status:'success'
        }
    }catch(error){
        return{
            status:'error',
            error:error
        }
    }
    
}

const createClip=async(annotationId,workspaceId)=>{
    // Trigger clip creation
    const data = JSON.stringify({
                "annotation_id": annotationId,
                "workspace_id" : workspaceId
    });
    // BACKEND_API
    // [ADDED_WORKSPACE_ID_PASSED]
    const axios_config = {
                method: 'post',
                url: getFunctionUrl('create_clip'),
                headers: {
                  'Content-Type': 'application/json'
                },
                data: data
    };
    try{
        // @ts-ignore
        const  newClipResponse=await axios(axios_config)
        console.log({newClipResponse});
        // TODO: Move this to backend
        const updatedAnnotation=await annotationsCollection.doc(annotationId).update({
            updatedOn:new Date(),
            type:'clip',
            lastUpdatedBy:  auth.currentUser.uid
        })
        return { status:'success',response:{newClipResponse,updatedAnnotation} }
    }catch(error){
        console.log('Error from clip creation API ',error);
        return{
            status:'error',
            error:error
        }
    }
    
     
                
}

// Calls the manage_project_tags service to delete a list of tags and remove it from all the notes
const deleteTagsFromProject=async(tagIds,projectId)=>{
    console.log('in deleteTagFromProject')
    try{
        const requestBody={
            "user_id": auth.currentUser.uid,
            "tag_ids": tagIds,
            "project_id":projectId
        }
        // BACKEND_API
        const requestUrl=getFunctionUrl('manage_project_tags') + '/delete-tags'
        const response=await axios.post(requestUrl,requestBody)
        return {status:'success',response};
    }catch(error){
        return { status:'error',error};
    }
}

// Calls the manage_project_tags service to delete a tag and remove it from all the notes
const deleteTagFromProject=async(tag,projectId)=>{
    console.log('in deleteTagFromProject')
    try{
        const requestBody={
            "user_id": auth.currentUser.uid,
            "tag_ids": [tag.id],
            "project_id":projectId
        }
        // BACKEND_API
        const requestUrl=getFunctionUrl('manage_project_tags') + '/delete-tags'
        const response=await axios.post(requestUrl,requestBody)
        return {status:'success',response};
    }catch(error){
        return { status:'error',error};
    }
}

// Calls the manage_project_tags service to edit a tag and update related notes
const editProjectTag=async(tag,projectId)=>{
    console.log('in editProjectTag')
    try{
        let updated_tag={
            "name":tag.name,
            "color":tag.color,
            "tagId":tag.id
        }
        if(tag.description)
            updated_tag['description'] = tag.description
        const requestBody={
            "user_id": auth.currentUser.uid,
            "project_id": projectId,
            "updated_tag" : updated_tag
          }
        // BACKEND_API
        const requestUrl=getFunctionUrl('manage_project_tags') + "/edit-tag"
        // const requestUrl="http://192.168.1.6:8081/edit-tag"
        const response=await axios.post(requestUrl,requestBody)
        return {status:'success',response};
    }catch(error){
        return { status:'error',error};
    }
}

export default {
    getAllWorkspaceTags,
    getAllTags,
    createNewTag,
    createNewTagForProject,
    deleteTag,
    deleteTagsForProject,
    createClip,
    getAllProjectAITags,
    deleteTagFromProject,
    deleteTagsFromProject,
    editProjectTag
}