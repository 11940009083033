export const AIAssistantEvents ={
    'OPEN_AI_ASSISTANT' :{
        event:'[AI Insights Wizard] Clicked on AI tab inside a project',
        action:'Clicked on AI tab from topnav inside a project'
    },
    "CLICK_SUMMARY_TEMPLATE" :{
        event:'[AI Insights Wizard] Clicked on AI template card',
        action:'Clicked on Summary Template from AI assistant page'
    },   
    "OPEN_EXISTING_AI_CONVERSATION" :{
        event:'[AI Insights Wizard] Opened existing AI conversation',
        action:'Opened existing AI conversation from AI assistant page'
    },  
    "CLICK_GENERATE_AI_SUMMARY" :{
        event:'[AI Insights Wizard] Clicked on generate AI summary',
        action:'Clicked on generate AI summary from modal'
    },
    "CLOSE_AI_INSTRUCTIONS_MODAL":{  
        event:'[AI Insights Wizard] Close AI instructions modal',
        action:'Clicked on cancel summary modal from modal'
    },
    "CLICK_AI_ASSISTANT_SUGGESTION":{
        event:'[AI Insights Wizard] Clicked on AI assistant suggestion',
        action:'Clicked on AI assistant suggestion from chat interface'
    },
    "ASKED_A_FOLLOW_UP_QUESTION":{
        event:'[AI Insights Wizard] Asked a follow up question',
        action:'Asked a follow up question from chat interface'
    },
    "CLICK_EDIT_AI_INSTRUCTIONS":{
        event:'[AI Insights Wizard] Clicked on edit AI instructions',
        action:'Clicked on edit AI instructions from chat interface'
    },
    "REGENERATED_AI_SUMMARY":{
        event:'[AI Insights Wizard] Regenerated AI summary',
        action:'Regenerated AI summary from chat interface'
    },
    "CLICK_COPY_AI_RESPONSE":{
        event:'[AI Insights Wizard] Clicked on copy AI response',
        action:'Clicked on copy AI response from chat interface'
    }
}